import { useStaticQuery, graphql } from 'gatsby'

const PageQuery = () => {
  const pageQuery = useStaticQuery(graphql`
    query {
      bannerBabiSM: imageSharp(fluid: {originalName: { regex: "/banner-babi-sm/" }}) {
        fluid(maxWidth: 312, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerBabiMD: imageSharp(fluid: {originalName: { regex: "/banner-babi-md/" }}) {
        fluid(maxWidth: 514, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
      bannerCanalDeAtendimentoAcessivel: imageSharp(fluid: {originalName: { regex: "/banner-canal-de-atendimento-acessivel/" }}) {
        fluid(maxWidth: 456, quality: 100) {
          ...GatsbyImageSharpFluid
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    }
  `)

  return pageQuery
}

export default PageQuery
