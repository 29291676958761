import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  display: flex;
  align-items: center;
  min-height: 477px;

  @media (min-width: ${breakpoints.md}) {
    min-height: 371px;
  }
  @media (min-width: ${breakpoints.lg}) {
    min-height: 379px;
  }
  @media (min-width: ${breakpoints.xl}) {
    min-height: 443px;
  }
`
