import styled from 'styled-components'
import { breakpoints } from 'src/styles/breakpoints'

export const Section = styled.section`
  display: flex;
  align-items: center;

  .btn-zero-style {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    outline: inherit;
  }
`

export const CardLink = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #DEDFE4;
  box-sizing: border-box;
  border-radius: 8px;
  background: #FFFFFF;
  padding: 16px;
  margin-top: 10px;
  width: auto;
  height: auto;

  @media (min-width: ${breakpoints.md}) {
    &:first-child {
      margin-top: 0;
    }
    margin-top: 20px;
  }

  @media (min-width: ${breakpoints.lg}) {
    padding: 16px;
  }
`
