import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

export const ImgSize = styled.div`
  .gatsby-image-wrapper {
    @media ${device.desktopXL} {
      max-width: 456px!important;
      max-height: none!important;
      float: right;
    }
  }
`
