import React from 'react'

import faqData from '../../pageContext.json'

// Components
import Faq from 'src/components/Faq'

// styles
import { Section } from './style'

const FaqSection = () => {
  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h2 className='fs-24 lh-30 fs-md-32 lh-md-40 fs-xl-40 lh-xl-50 mb-5 text-grayscale--500 fw-600 text-md-center'>Perguntas frequentes</h2>
            <Faq
              columns={{ sm: 1, md: 1, lg: 1, xl: 1 }}
              searchBg='#F5F6FA'
              answerData={faqData.structuredData.faq}
            />
          </div>
        </div>
      </div>
    </Section>
  )
}

export default FaqSection
