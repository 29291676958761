import React from 'react'
import Img from 'gatsby-image'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ChatWindow from 'components/ChatWindow'
import { EventCaller } from 'inter-site-components'

import pageQuery from '../../pageQuery'

import { ImgSize } from './style'

const FaleComABabi = () => {
  const data = pageQuery()
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <section className='bg-gray py-5 pb-md-0 d-flex align-items-center'>
      <div className='container'>
        <div className='row d-flex align-items-center'>
          <ImgSize className='col-12 col-md-6 col-lg-5 offset-lg-1 mb-4 mb-md-0 order-md-last d-flex justify-content-center'>
            <Img fluid={data.bannerBabiSM.fluid} alt='Babi, atendente virtual do Inter' className='d-md-none' />
            <Img fluid={data.bannerBabiMD.fluid} alt='Babi, atendente virtual do Inter' className='d-none d-md-block' />
          </ImgSize>
          <div className='col-12 col-md-6'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-4 mb-md-3'>
              <span className='d-xl-block'>Fale com a Babi, nossa </span>assistente virtual
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 text-grayscale--500 mb-4'>Ainda não achou a resposta que precisa? A Babi, nossa assistente virtual de atendimento, pode te ajudar. Ela é especialista em todos os produtos do Inter! Clique em Falar com a Babi para iniciar seu atendimento.</p>
            <div
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_02',
                  section_name: 'Fale com a Babi, nossa assistente virtual ',
                  element_action: 'click button',
                  element_name: 'Falar com a Babi',
                })
              }}
            >
              <EventCaller
                action='sendGAEvent' parameters='Chat;Botao;send'
              >
                <button
                  className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mw-100'
                  title='Falar com a Babi'
                  aria-label='Falar com a Babi'
                  onClick={ChatWindow()}
                >
                  Falar com a Babi
                </button>
              </EventCaller>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default FaleComABabi
