import React from 'react'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'

import { Section } from './style'

export const Hero = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section
      className='d-flex justify-content-end justify-content-md-center flex-column py-5'
      role='img' aria-label='Homem sorrindo segurando o celular enquanto toma café'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12 col-md-6 col-xl-5 offset-md-6 offset-xl-7'>
            <h1 className='fs-16 lh-20 fs-lg-20 lh-lg-25 text-grayscale--500'>
              Atendimento Inter
            </h1>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-grayscale--500'>Central de Ajuda</h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--500 fw-400 mb-lg-4 pb-lg-2'>
              Na Central de Ajuda, você encontra uma série de artigos com resposta para suas principais dúvidas!
            </p>

            <a
              href='https://ajuda.bancointer.com.br/'
              target='_blank'
              rel='noreferrer'
              className='btn btn-orange--extra btn--lg rounded-2 fs-14 text-none mw-100'
              title='Acessar Central de Ajuda'
              aria-label='Acessar Central de Ajuda'
              onClick={() => {
                sendDatalayerEvent({
                  section: 'dobra_01',
                  section_name: 'Central de Ajuda',
                  element_action: 'click button',
                  element_name: 'Acessar Central de Ajuda',
                  redirect_url: 'https://ajuda.bancointer.com.br/',
                })
              }}
            >
              Acessar Central de Ajuda
            </a>
          </div>
        </div>
      </div>
    </Section>
  )
}
