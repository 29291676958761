import React from 'react'
import Layout from 'src/layouts/BaseLayout'

import pageContext from './pageContext.json'

import { Wrapper } from './style'

import { Hero } from './sections/Hero/_index'
import FaleComABabi from './sections/fale-com-a-babi/_index'
import CentralDeAtendimento from './sections/canais-de-atendimento/_index'
import PrecisaDeAjuda from './sections/precisa-de-ajuda/_index'
import { RedesSociais } from './sections/redes-sociais/_index'
import Faq from './sections/faq/_index'

const CanaisDeAtendimento = () => {
  return (
    <Wrapper>
      <Layout pageContext={pageContext}>
        <Hero />
        <FaleComABabi />
        <CentralDeAtendimento />
        <PrecisaDeAjuda />
        <RedesSociais />
        <Faq />
      </Layout>
    </Wrapper>
  )
}

export default CanaisDeAtendimento
