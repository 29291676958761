import styled from 'styled-components'
import { device } from 'src/styles/breakpoints'

import bgHeroSM from '../../assets/images/hero-sm.png'
import bgHeroMD from '../../assets/images/hero-md.png'
import bgHeroLG from '../../assets/images/hero-lg.png'
import bgHeroXL from '../../assets/images/hero-xl.png'

export const Section = styled.section`
  min-height: 587px;
  background: url(${bgHeroSM}) center center no-repeat;
  background-size: cover;

  @media ${device.tablet} {
    min-height: 544px;
    background: url(${bgHeroMD}) left center no-repeat;
    background-size: contain;
  }

  @media ${device.desktopLG} {
    min-height: 544px;
    background: url(${bgHeroLG}) left center no-repeat;
    background-size: contain;
  }

  @media ${device.desktopXL} {
    min-height: 600px;
    background: url(${bgHeroXL}) left center no-repeat;
    background-size: contain;
  }
`
