import React from 'react'

import Instagram from '@interco/icons/build-v4/orangeds/MD/instagram'
import Linkedin from '@interco/icons/build-v4/orangeds/MD/linkedin'
import Facebook from '@interco/icons/build-v4/orangeds/MD/facebook'
import Twitter from '@interco/icons/build-v4/orangeds/MD/twitter'
import Youtube from '@interco/icons/build-v4/orangeds/MD/youtube'
import TikTok from '@interco/icons/build-v4/orangeds/MD/tik-tok'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { Section } from './style'

export const RedesSociais = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='bg-gray--100 py-5'>
      <div className='container'>
        <div className='row align-items-md-center'>
          <div className='col-12 col-md-6 mb-4'>
            <h2 className='fs-24 lh-30 fs-lg-40 lh-lg-50 text-grayscale--500 mb-4'>Redes sociais Inter</h2>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--500'>
              Siga o Inter nas redes sociais e fique por dentro de todas as novidades do seu Super App.
            </p>
            <p className='fs-16 lh-19 fs-lg-18 lh-lg-22 text-grayscale--500'>Lembre-se: para sua segurança, <strong>verifique sempre o selo oficial das nossas redes!</strong> Assim você tem a certeza de que somos nós falando com você.</p>
          </div>
          <div className='col-12 col-md-6 social-networks-color'>
            <div className='row'>
              <div className='d-flex flex-wrap cards-gap col-lg-6'>
                <a
                  href='https://www.facebook.com/interbrasil'
                  target='_blank'
                  rel='noreferrer'
                  title='Siga-nos no Facebook'
                  className='social-cards bg-white d-block'
                  aria-label='Siga-nos no Facebook'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Redes sociais',
                      section_name: 'Facebook',
                      redirect_url: 'https://www.facebook.com/interbrasil',
                    })
                  }}
                >
                  <div className='d-flex align-items-center justify-content-center h-100'>
                    <div className='mr-3'>
                      <Facebook width={22} height={22} color='#1277ED' aria-hidden='true' />
                    </div>
                    <div className='text-block-size'>
                      <p className='fs-16 lh-20 fw-700 text-grayscale--400 mb-0'>Facebook</p>
                    </div>
                  </div>
                </a>

                <a
                  href='https://www.instagram.com/interbr'
                  target='_blank'
                  rel='noreferrer'
                  className='social-cards bg-white d-block'
                  title='Siga-nos no Instagram'
                  aria-label='Siga-nos no Instagram'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Redes sociais',
                      section_name: 'Instagram',
                      redirect_url: 'https://www.instagram.com/interbr',
                    })
                  }}
                >
                  <div className='d-flex align-items-center justify-content-center h-100'>
                    <div className='mr-3'>
                      <Instagram width={22} height={22} aria-hidden='true' />
                    </div>
                    <div className='text-block-size'>
                      <p className='fs-16 lh-20 fw-700 text-grayscale--400 mb-0'>Instagram</p>
                    </div>
                  </div>
                </a>

                <a
                  href='https://www.linkedin.com/company/inter'
                  target='_blank'
                  rel='noreferrer'
                  title='Siga-nos no Linkedin'
                  className='social-cards bg-white d-block'
                  aria-label='Siga-nos no Linkedin'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Redes sociais',
                      section_name: 'Linkedin',
                      redirect_url: 'https://www.linkedin.com/company/inter',
                    })
                  }}
                >
                  <div className='d-flex align-items-center justify-content-center h-100'>
                    <div className='mr-3'>
                      <Linkedin width={22} height={22} color='#0077B2' aria-hidden='true' />
                    </div>
                    <div className='text-block-size'>
                      <p className='fs-16 lh-20 fw-700 text-grayscale--400 mb-0'>LinkedIn</p>
                    </div>
                  </div>
                </a>
              </div>
              <div className='d-flex flex-wrap cards-gap col-lg-6 mt-md-3 mt-lg-0 mt-3 mt-md-0'>
                <a
                  href='https://twitter.com/interbr'
                  target='_blank' rel='noreferrer'
                  title='Siga-nos no Twitter'
                  className='social-cards bg-white d-block'
                  aria-label='Siga-nos no Twitter'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Redes sociais',
                      section_name: 'Twitter',
                      redirect_url: 'https://twitter.com/interbr',
                    })
                  }}
                >
                  <div className='d-flex align-items-center justify-content-center h-100'>
                    <div className='mr-3'>
                      <Twitter width={22} height={22} color='#52ACEA' aria-hidden='true' />
                    </div>
                    <div className='text-block-size'>
                      <p className='fs-16 lh-20 fw-700 text-grayscale--400 mb-0'>Twitter</p>
                    </div>
                  </div>
                </a>

                <a
                  href='https://www.tiktok.com/@interbr'
                  target='_blank'
                  rel='noreferrer'
                  title='Siga-nos no TikTok'
                  className='social-cards bg-white d-block'
                  aria-label='Siga-nos no TikTok'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Redes sociais',
                      section_name: 'Tiktok',
                      redirect_url: 'https://www.tiktok.com/@interbr',
                    })
                  }}
                >
                  <div className='d-flex align-items-center justify-content-center h-100'>
                    <div className='mr-3'>
                      <TikTok width={22} height={22} color='#161616' aria-hidden='true' />
                    </div>
                    <div className='text-block-size'>
                      <p className='fs-16 lh-20 fw-700 text-grayscale--400 mb-0'>TikTok</p>
                    </div>
                  </div>
                </a>

                <a
                  href='https://www.youtube.com/@interbr'
                  target='_blank'
                  rel='noreferrer'
                  title='Siga-nos no Youtube'
                  className='social-cards bg-white d-block'
                  aria-label='Siga-nos no Youtube'
                  onClick={() => {
                    sendDatalayerEvent({
                      section: 'dobra_05',
                      element_action: 'click button',
                      element_name: 'Redes sociais',
                      section_name: 'Youtube',
                      redirect_url: 'https://www.youtube.com/@interbr',
                    })
                  }}
                >
                  <div className='d-flex align-items-center justify-content-center h-100'>
                    <div className='mr-3'>
                      <Youtube width={22} height={22} color='#FF0811' aria-hidden='true' />
                    </div>
                    <div className='text-block-size'>
                      <p className='fs-16 lh-20 fw-700 text-grayscale--400 mb-0'>Youtube</p>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Section>
  )
}
