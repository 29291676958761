import React from 'react'

import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import { Section } from './style'
import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import ScrollTo from 'src/components/ScrollTo'

const PrecisaDeAjuda = () => {
  const [ sendDatalayerEvent ] = useDataLayer()

  return (
    <Section className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12 col-lg-8 offset-lg-2'>
            <h2 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 text-center mb-3'>
              Precisa de ajuda relacionada a investimentos?
            </h2>
            <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-22 fw-400 text-grayscale--500 text-center mb-5'>
              Além de falar com a gente pelos nossos
              <ScrollTo
                to='#canais-de-atendimento'
                title='canais de atendimento'
                className='fw-700 text-orange--extra'
                section='dobra_05'
                sectionName='canais de atendimento'
                elementName='Precisa de ajuda relacionada a investimentos?'
              >
                {' '}<strong>canais de atendimento</strong>
              </ScrollTo>,
              você também pode entrar em contato com a CVM e com a BSM. Consulte também a
              <a
                href='https://inter.co/pra-voce/investimentos/#Quais-sao-os-canais-de-atendimento-disponibilizados-aos-investidores-e-como-acessa-los'
                target='_blank'
                rel='noreferrer'
                title='lista de documentos'
                className='fw-700 text-orange--extra'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    element_name: 'Precisa de ajuda relacionada a investimentos?',
                    section_name: ' lista de documentos',
                    redirect_url: 'https://inter.co/pra-voce/investimentos/#Quais-sao-os-canais-de-atendimento-disponibilizados-aos-investidores-e-como-acessa-los',
                  })
                }}
              >
                {' '}lista de documentos
              </a> que serão solicitados pela CVM.
            </p>
          </div>

          <div className='col-12 col-md-6 text-center mb-4 mb-md-0'>
            <div className='mr-4' aria-hidden='true'>
              <OrangeIcon size='XL' icon='computer' color='#ff7a00' />
            </div>
            <div>
              <h3 className='fs-16 lh-20 fs-xl-20 lh-xl-25 fw-600 text-grayscale--500 mb-2'>CVM - Comissão de Valores Mobiliários</h3>
              <a
                href='https://www.gov.br/cvm/pt-br/canais_atendimento/consultas-reclamacoes-denuncias'
                title='Serviço de Atendimento ao Cidadão'
                target='_blank'
                rel='noreferrer'
                className='fs-14 lh-17 fs-lg-16 lh-lg-19 fw-700 text-orange--extra d-block'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    element_name: 'Serviço de Atendimento ao Cidadão',
                    section_name: 'Precisa de ajuda relacionada a investimentos?',
                    redirect_url: 'https://www.gov.br/cvm/pt-br/canais_atendimento/consultas-reclamacoes-denuncias',
                  })
                }}
              >
                Serviço de Atendimento ao Cidadão
              </a>
            </div>
          </div>

          <div className='col-12 col-md-6 text-center px-md-0'>
            <div className='mr-4' aria-hidden='true'>
              <OrangeIcon size='XL' icon='computer' color='#ff7a00' />
            </div>
            <div>
              <h3 className='fs-16 lh-20 fs-xl-20 lh-xl-25 fw-600 text-grayscale--500 mb-2'>BSM Supervisão de Mercados</h3>
              <a
                href='https://www.bsmsupervisao.com.br/ressarcimento-de-prejuizos/como-funciona'
                title='MRP - Mecanismo de Ressarcimento de Prejuízos'
                target='_blank'
                rel='noreferrer'
                className='fs-14 lh-17 fs-lg-16 lh-lg-19 fw-700 text-orange--extra d-block'
                onClick={() => {
                  sendDatalayerEvent({
                    section: 'dobra_05',
                    element_action: 'click button',
                    element_name: 'MRP - Mecanismo de Ressarcimento de Prejuízos',
                    section_name: 'Precisa de ajuda relacionada a investimentos?',
                    redirect_url: 'https://www.bsmsupervisao.com.br/ressarcimento-de-prejuizos/como-funciona',
                  })
                }}
              >
                MRP - Mecanismo de Ressarcimento de Prejuízos
              </a>
            </div>

          </div>
        </div>
      </div>
    </Section>
  )
}

export default PrecisaDeAjuda
