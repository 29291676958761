import React from 'react'

import canaisJSON from '../../assets/data/canais.json'

import ChatWindow from 'components/ChatWindow'
import { EventCaller } from 'inter-site-components'
import OrangeIcon from 'src/components/UI/MarkdownIcon/OrangeDsIcon'

import useDataLayer from 'src/hooks/useDataLayer/dataLayerBody'
import { CardLink, Section } from './style'

type canaisJSONprops = {
  icon: string;
  title: string;
  subtitle?: string;
  linkText?: string;
  link?: string;
  hasLink?: boolean;
}

interface ICanaisData {
  centralDeAtendimento: canaisJSONprops[];
  sac: canaisJSONprops[];
  ouvidoria: canaisJSONprops[];
  deficienciaAuditiva: canaisJSONprops[];
}

const CentralDeAtendimento = () => {
  const [ sendDatalayerEvent ] = useDataLayer()
  const canaisData: ICanaisData = canaisJSON

  return (
    <Section id='canais-de-atendimento' className='py-5'>
      <div className='container'>
        <div className='row d-flex align-items-center justify-content-between'>
          <div className='col-12'>
            <h2 className='fs-16 lh-20 fs-md-20 lh-md-25 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 text-center fw-600 text-grayscale--500 mb-5 pb-lg-4'>
              Canais de atendimento
              <span className='d-block d-md-inline'> e telefones Inter</span>
            </h2>
          </div>

          <div className='row d-flex align-items-md-center justify-content-md-between mb-md-5 pb-md-4'>
            <div className='col-12 col-md-6 col-xl-5'>
              <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-2'>Central de Atendimento</h3>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--500'>
                Para falar com a gente sobre consultas, informações, transações, cancelamentos, renegociação de
                dívida e orientações para uso dos canais virtuais.
              </p>
            </div>

            <div className='col-12 col-md-6 offset-xl-1'>
              {
              canaisData.centralDeAtendimento.map((item: canaisJSONprops) => (
                <CardLink key={item.title}>
                  <div className='mr-4' aria-hidden='true'>
                    <OrangeIcon size='LG' icon={item.icon} color='#ff7a00' />
                  </div>
                  <div>
                    <h4 className='fs-16 lh-20 fs-lg-24 lh-lg-30 mb-2 fw-700 text-grayscale--500 font-sora'>{item.title}</h4>
                    <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 mb-2 m-md-0 text-grayscale--400'>{item.subtitle}</p>
                    {item.hasLink && (
                      <div
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_03',
                            element_action: 'click button',
                            element_name: item.linkText ? item.linkText : 'null',
                            section_name: 'Canais de atendimento e telefones Inter',
                          })
                        }}
                      >
                        <EventCaller
                          action='sendGAEvent'
                          parameters='Chat;Botao;send'
                        />
                        <button
                          title={item.subtitle}
                          aria-label='Acessar chat 24 horas'
                          className='fs-14 lh-18 fw-700 text-orange--extra btn-zero-style'
                          onClick={ChatWindow()}
                        >
                          {item.linkText}
                        </button>
                      </div>
                    )}
                  </div>
                </CardLink>
              ))
            }
            </div>
          </div>

          <div className='row d-flex align-items-md-center justify-content-md-between mb-md-5 pb-md-4'>
            <div className='col-12 col-md-6 pt-5 pt-md-0 col-xl-5'>
              <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-2'>SAC</h3>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--500'>
                Para registrar reclamações, cancelamentos, sugestões, elogios e informações, o SAC é o
                canal de atendimento ideal para você!
              </p>
            </div>
            <div className='col-12 col-md-6 offset-xl-1'>
              {
                canaisData.sac.map((item: canaisJSONprops) => (
                  <CardLink key={item.title}>
                    <div className='mr-4' aria-hidden='true'>
                      <OrangeIcon size='LG' icon={item.icon} color='#ff7a00' />
                    </div>
                    <div>
                      <h4 className='fs-16 lh-20 fs-lg-24 lh-lg-30 mb-2 fw-700 text-grayscale--500 font-sora'>{item.title}</h4>
                      <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 mb-2 text-grayscale--400'>{item.subtitle}</p>
                      <a
                        href={item.link}
                        title={item.subtitle}
                        className='fs-14 lh-18 fw-700 text-orange--extra'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_03',
                            element_action: 'click button',
                            element_name: item.linkText ? item.linkText : 'null',
                            section_name: 'Canais de atendimento e telefones Inter',
                            redirect_url: item.link,
                          })
                        }}
                      >
                        {item.linkText}
                      </a>
                    </div>
                  </CardLink>
                ))
              }
            </div>
          </div>

          <div className='row d-flex align-items-md-center justify-content-md-between mb-md-5 pb-md-4'>
            <div className='col-12 col-md-6 col-xl-5 pt-5 pt-md-0'>
              <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-2'>Ouvidoria</h3>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--500'>
                Para você que já recebeu nosso atendimento em outros canais, mas ainda não teve a solução que precisava.
              </p>
            </div>

            <div className='col-12 col-md-6 offset-xl-1'>
              {
                canaisData.ouvidoria.map((item: canaisJSONprops) => (
                  <CardLink key={item.title}>
                    <div className='mr-4' aria-hidden='true'>
                      <OrangeIcon size='LG' icon={item.icon} color='#ff7a00' />
                    </div>
                    <div>
                      <h4 className='fs-16 lh-20 fs-lg-24 lh-lg-30 mb-2 fw-700 text-grayscale--500 font-sora'>{item.title}</h4>
                      <p className='fs-14 lh-17 fs-lg-18 lh-lg-22 mb-2 text-grayscale--400'>{item.subtitle}</p>
                      <a
                        href={item.link}
                        title={item.subtitle}
                        aria-label='Acessar o canal de atendimento online'
                        className='fs-14 lh-18 fs-lg-18 lh-lg-21 fw-700 text-orange--extra'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_03',
                            element_action: 'click button',
                            element_name: item.linkText ? item.linkText : 'null',
                            section_name: 'Canais de atendimento e telefones Inter',
                            redirect_url: item.link,
                          })
                        }}
                      >
                        {item.linkText}
                      </a>
                    </div>
                  </CardLink>
                ))
              }
            </div>
          </div>
          <div className='row d-flex align-items-md-center justify-content-md-between mb-md-5 pb-md-4'>
            <div className='col-12 col-md-6 col-xl-5 pt-5 pt-md-0'>
              <h3 className='fs-24 lh-30 fs-lg-32 lh-lg-40 fs-xl-40 lh-xl-50 fw-600 text-grayscale--500 mb-2'>
                Atendimento às pessoas com deficiência auditiva ou de fala
              </h3>
              <p className='fs-14 lh-17 fs-lg-16 lh-lg-19 fs-xl-18 lh-xl-21 text-grayscale--500'>
                Atendimento em Libras com tradução simultânea de intérprete para informações, reclamações,
                cancelamento, outros produtos e serviços.
              </p>
            </div>
            <div className='col-12 col-md-6 offset-xl-1'>
              {
                canaisData.deficienciaAuditiva.map((item: canaisJSONprops) => (
                  <CardLink key={item.title}>
                    <div className='mr-4' aria-hidden='true'>
                      <OrangeIcon size='LG' icon={item.icon} color='#ff7a00' />
                    </div>
                    <div>
                      <h4 className='fs-16 lh-20 fs-lg-24 lh-lg-30 mb-2 fw-700 text-grayscale--500 font-sora'>{item.title}</h4>
                      <a
                        href={item.link}
                        title={item.subtitle}
                        aria-label='Iniciar atendimento em libras'
                        className='fs-14 lh-18 fs-lg-18 lh-lg-21 fw-700 text-orange--extra'
                        onClick={() => {
                          sendDatalayerEvent({
                            section: 'dobra_03',
                            element_action: 'click button',
                            element_name: item.linkText ? item.linkText : 'null',
                            section_name: 'Canais de atendimento e telefones Inter',
                            redirect_url: item.link,
                          })
                        }}
                      >
                        {item.linkText}
                      </a>
                    </div>
                  </CardLink>
                ))
              }
            </div>
          </div>
          <p className='fs-12 lh-15 text-grayscale--400'><strong>Não ligamos pra você por esses telefones.</strong> Também não solicitamos dados pessoais, senha da conta, código de transação por telefone. Estes números são apenas para você fazer ligações para o Inter.</p>
        </div>
      </div>
    </Section>
  )
}

export default CentralDeAtendimento
