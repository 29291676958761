import styled from 'styled-components'

export const Section = styled.section`
  .cards-gap {
    gap: 16px;
  }

  .social-cards {
    height: 72px;
    width: 312px;
    border-radius: 16px;
    border: 1px solid #DEDFE4;
  }

  .text-block-size {
    width: 90px;
  }

`
